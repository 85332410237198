import React, { Fragment, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import MenuMaterial from "@mui/material/Menu";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import Drawer from "@mui/material/Drawer";

// icons
import {
  Person,
  Email,
  Category,
  Logout,
  Inventory,
  ChangeHistory,
  QrCode,
  AccountCircle,
  Notifications,
  Menu,
} from "@mui/icons-material";

import logo from "../Images/logo.png";
import userImage from "../Images/user_icon.png";

import { funtion } from "../config";

const drawerWidth = 240;
const navItems = [
  {
    titulo: "Usuarios",
    icono: Person,
    estado: funtion.usuarios,
    vinculo: "/usuarios",
  },
  {
    titulo: "Mensaje",
    icono: Email,
    estado: funtion.mensajes,
    vinculo: "/mensajes",
  },
  {
    titulo: "Categorias",
    icono: Category,
    estado: funtion.categorias,
    vinculo: "/categorias",
  },
  {
    titulo: "Sub Categorias",
    icono: ChangeHistory,
    estado: funtion.subcategorias,
    vinculo: "/sub-categorias",
  },
  {
    titulo: "Productos",
    icono: Inventory,
    estado: funtion.prouctos,
    vinculo: "/prouctos",
  },
  {
    titulo: "Codigos de visita",
    icono: QrCode,
    estado: true,
    vinculo: "/codigos-visita",
  },
];

export default function PrimarySearchAppBar({ title, window, user }) {
  const history = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const cerrarSesion = () => {
    sessionStorage.clear();
    Swal.fire({
      title: "Sesión Cerrada",
      text: "Sesión fue cerrada correctamente",
      confirmButtonColor: "#2DCD22",
      confirmButtonText: "Continuar",
      icon: "success",
    }).then(() => {
      history("/");
    });
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <MenuMaterial
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </MenuMaterial>
  );

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <div className="sidenav-user">
        <div>
          <img src={userImage} alt="Imagen Usuario Administrador" />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <p>{user.nombre}</p>
            <p>{user.correo}</p>
          </div>
        </div>
      </div>
      <Divider />
      <List>
        {navItems.map(
          (item) =>
            item.estado && (
              <ListItem key={item.titulo} disablePadding>
                <Link style={{ width: "100%" }} to={item.vinculo}>
                  <ListItemButton className="sidenav-opcion">
                    <p>
                      <item.icono />
                      {item.titulo}
                    </p>
                  </ListItemButton>
                </Link>
              </ListItem>
            )
        )}
      </List>
      <ListItem key="cerrar_sesion" disablePadding>
        <ListItemButton className="sidenav-opcion">
          <button onClick={cerrarSesion}>
            <p>
              <Logout /> Cerrar sesión
            </p>
          </button>
        </ListItemButton>
      </ListItem>
    </Box>
  );

  return (
    <Fragment>
      <AppBar style={{ background: "#ffffff" }} position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="#2A79AE"
            onClick={handleDrawerToggle}
            aria-label="open drawer"
            sx={{ mr: 2 }}
          >
            <Menu />
          </IconButton>
          {/* <img src={userImage} alt="Imagen Usuario Administrador" /> */}
          <img src={logo} alt="logo michel" />
          <Box sx={{ flexGrow: 1 }} />
          <div className="sidenav-title">
            <p>{title}</p>
          </div>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            {funtion.mensajes && (
              <IconButton
                size="large"
                aria-label="show 17 new notifications"
                color="#2A79AE"
              >
                <Badge badgeContent={17} color="error">
                  <Notifications />
                </Badge>
              </IconButton>
            )}
            {funtion.usuarios && (
              <IconButton
                size="large"
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="#2A79AE"
              >
                <AccountCircle />
              </IconButton>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      {renderMenu}
    </Fragment>
  );
}

import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import ReactDOM from "react-dom";

import Grid from "@mui/material/Unstable_Grid2";
import TextField from "@mui/material/TextField";

import useDatabase from "../../Hooks/useDatabase";

// iconos
import { Add, BorderColor, Delete } from "@mui/icons-material";

// configuracion de la vista
import { codigosVisitas } from "../../config.js";
import Swal from "sweetalert2";

import { globalContext } from "../../Context/globalContext";

const NuevoCodigo = () => {
  const { setTitle } = useContext(globalContext);
  setTitle("Agregar codigo de visita");

  const [consulta, setConsulta] = useState({ consulta: "" });

  const [imagenesAntes, setImagenesAntes] = useState([]);
  const [imagenesDespues, setImagenesDespues] = useState([]);
  const [filesAntes, setFilesAntes] = useState([]);
  const [filesDespues, setFilesDespues] = useState([]);

  const [state, setState] = useState({
    clienta: "",
    visto: 0,
  });

  useDatabase(consulta);

  const agregarCodigo = async (e) => {
    e.preventDefault();

    if (filesDespues.length <= 0 && filesAntes.length <= 0) {
      return Swal.fire({
        title: "Error",
        text: "Faltan datos por llenar",
        confirmButtonColor: "#2DCD22",
        confirmButtonText: "Continuar",
        icon: "error",
      });
    }

    setConsulta({
      consulta: "agregarDocumento",
      collectionName: codigosVisitas,
      state,
      files: [filesAntes, filesDespues],
      updates: [[], []],
      deletes: [],
      urlRedirect: "/codigos-visita",
    });
  };

  const leerDato = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const verImagen = (imagenPrevia) => {
    let contenedor = document.getElementById("fondo-imagen");
    contenedor.className = "fondo-imagen";

    const imagen = <img src={imagenPrevia} alt="Vista previa de imagen" />;

    ReactDOM.render(imagen, contenedor);
  };

  //   agregar imagenes
  const leerImagenesAntes = async (e) => {
    // copia de los archivos agregados con anterioridad y los nuevos
    let archivosAntesCopia = [...filesAntes];

    for (let index = 0; index < e.target.files.length; index++) {
      const file = e.target.files[index];
      archivosAntesCopia.push(file);
    }

    setFilesAntes(archivosAntesCopia);

    // algoritmo de precarga
    let imagenesPrecarga = [...imagenesAntes];

    await new Promise((resolve, reject) => {
      for (let index = 0; index < e.target.files.length; index++) {
        const file = e.target.files[index];

        let fileReader = new FileReader();
        fileReader.addEventListener("load", function (evt) {
          imagenesPrecarga.push(fileReader.result);

          if (index === e.target.files.length - 1) {
            resolve(true);
          }
        });

        fileReader.readAsDataURL(file);
      }
    });

    setImagenesAntes(imagenesPrecarga);
  };

  const leerImagenesDespues = async (e) => {
    // copia de los archivos agregados con anterioridad y los nuevos
    let archivosDespuesCopia = [...filesDespues];

    for (let index = 0; index < e.target.files.length; index++) {
      const file = e.target.files[index];
      archivosDespuesCopia.push(file);
    }

    setFilesDespues(archivosDespuesCopia);

    // algoritmo de precarga
    let imagenesPrecarga = [...imagenesDespues];

    await new Promise((resolve, reject) => {
      for (let index = 0; index < e.target.files.length; index++) {
        const file = e.target.files[index];

        let fileReader = new FileReader();
        fileReader.addEventListener("load", function (evt) {
          imagenesPrecarga.push(fileReader.result);

          if (index === e.target.files.length - 1) {
            resolve(true);
          }
        });

        fileReader.readAsDataURL(file);
      }
    });

    setImagenesDespues(imagenesPrecarga);
  };
  //   fin - agregar imagenes

  //   *** imagenes por subir
  //   eliminar imagenes que estan por subir
  const eliminarImagenAntes = (index) => {
    // imagenes que se muestran
    let eliminarImagenesAntesCopia = [...imagenesAntes];
    eliminarImagenesAntesCopia.splice(index, 1);
    setImagenesAntes(eliminarImagenesAntesCopia);

    // archivos que se van a mandar
    let eliminarFilesAntesCopia = [...filesAntes];
    eliminarFilesAntesCopia.splice(index, 1);
    setFilesAntes(eliminarFilesAntesCopia);
  };

  //   eliminar imagenes que estan por subir
  const eliminarImagenDespues = (index) => {
    // imagenes que se muestran
    let eliminarImagenesDespuesCopia = [...imagenesDespues];
    eliminarImagenesDespuesCopia.splice(index, 1);
    setImagenesDespues(eliminarImagenesDespuesCopia);

    // archivos que se van a mandar
    let eliminarFilesDespuesCopia = [...filesDespues];
    eliminarFilesDespuesCopia.splice(index, 1);
    setFilesDespues(eliminarFilesDespuesCopia);
  };

  //   editar imagenes que estan por subir
  const editarImagenAntes = async (e, index) => {
    // imagenes que se muestran
    // algoritmo de precarga
    let copiaImagenesAntes = [...imagenesAntes];
    await new Promise((resolve, reject) => {
      const file = e.target.files[0];

      let fileReader = new FileReader();
      fileReader.addEventListener("load", function (evt) {
        copiaImagenesAntes[index] = fileReader.result;

        resolve(true);
      });

      fileReader.readAsDataURL(file);
    });

    setImagenesAntes(copiaImagenesAntes);
    // fin - logica de actualizacion de imagen visual

    // archivos que se van a mandar e.target.files[0]
    let filesAntesCopia = [...filesAntes];
    filesAntesCopia[index] = e.target.files[0];
    setFilesAntes(filesAntesCopia);
  };

  //   editar imagenes que estan por subir
  const editarImagenDespues = async (e, index) => {
    // imagenes que se muestran
    // algoritmo de precarga
    let copiaImagenesDespues = [...imagenesDespues];
    await new Promise((resolve, reject) => {
      const file = e.target.files[0];

      let fileReader = new FileReader();
      fileReader.addEventListener("load", function (evt) {
        copiaImagenesDespues[index] = fileReader.result;

        resolve(true);
      });

      fileReader.readAsDataURL(file);
    });

    setImagenesDespues(copiaImagenesDespues);
    // fin - logica de actualizacion de imagen visual

    // archivos que se van a mandar e.target.files[0]
    let filesDespuesCopia = [...filesDespues];
    filesDespuesCopia[index] = e.target.files[0];
    setFilesDespues(filesDespuesCopia);
  };
  //   fin - *** imagenes por subir

  return (
    <div className="row contenedor-1">
      <form onSubmit={agregarCodigo}>
        {/* <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p className="titulo-1">Agregar codigo de visita</p>
        </div> */}
        <Grid container rowSpacing={0} columnSpacing={3}>
          <Grid md={12} lg={6} className="formulario-inputs">
            <div style={{ padding: "0px 20px" }}>
              <TextField
                style={{ marginTop: "25px" }}
                label="Nombre de la clienta"
                placeholder="Nombre de la clienta"
                onChange={leerDato}
                id="clienta"
                name="clienta"
                variant="outlined"
                fullWidth
                required
              />
              <TextField
                style={{ marginTop: "25px" }}
                label="Codigo de la visita"
                placeholder="Codigo de la visita"
                onChange={leerDato}
                id="codigo"
                name="codigo"
                variant="outlined"
                fullWidth
                required
              />
              <TextField
                style={{ marginTop: "25px" }}
                label="Fecha de la visita"
                placeholder="Fecha de la visita"
                onChange={leerDato}
                id="fecha"
                name="fecha"
                variant="outlined"
                fullWidth
                required
              />
            </div>
          </Grid>
          <Grid container xs={12} md={12} lg={6} style={{ marginTop: "30px" }}>
            <Grid xs={6}>
              <div
                style={{
                  display: "flex",
                  gap: "15px",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p>
                  <b>Antes</b>
                </p>
                <div>
                  <input
                    id="imagenesAntes"
                    name="imagenesAntes"
                    className="agregar-imagen"
                    placeholder="+"
                    type="file"
                    accept="image/*"
                    onChange={leerImagenesAntes}
                    multiple
                  />
                  <label for="imagenesAntes">
                    <Add fontSize="small" />
                  </label>
                </div>
              </div>
              <div className="galeria-imagenes">
                {imagenesAntes.map((imagen, index) => (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <div className="contenedor-imagen">
                      <img
                        onClick={() => {
                          verImagen(imagen);
                        }}
                        src={imagen}
                        alt={state.clienta}
                      />
                    </div>
                    <div>
                      <input
                        style={{ display: "none" }}
                        id={"imagenesAntesNuevas-" + index}
                        name="imagenesAntesNuevas"
                        type="file"
                        accept="image/*"
                        onChange={(e) => {
                          editarImagenAntes(e, index);
                        }}
                      />
                      <label for={"imagenesAntesNuevas-" + index}>
                        <BorderColor />
                      </label>
                    </div>
                    <Delete
                      onClick={() => {
                        eliminarImagenAntes(index);
                      }}
                    />
                  </div>
                ))}
              </div>
            </Grid>

            <Grid xs={6}>
              <div
                style={{
                  display: "flex",
                  gap: "15px",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p>
                  <b>Despues</b>
                </p>
                <div>
                  <input
                    id="imagenesDespues"
                    name="imagenesDespues"
                    className="agregar-imagen"
                    placeholder="+"
                    type="file"
                    accept="image/*"
                    onChange={leerImagenesDespues}
                    multiple
                  />
                  <label for="imagenesDespues">
                    <Add fontSize="small" />
                  </label>
                </div>
              </div>
              <div className="galeria-imagenes">
                {imagenesDespues.map((imagen, index) => (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <div className="contenedor-imagen">
                      <img
                        onClick={() => {
                          verImagen(imagen);
                        }}
                        src={imagen}
                        alt={state.clienta}
                      />
                    </div>
                    <div>
                      <input
                        style={{ display: "none" }}
                        id={"imagenesDespuesNuevo-" + index}
                        name="imagenesDespuesNuevo"
                        type="file"
                        accept="image/*"
                        onChange={(e) => {
                          editarImagenDespues(e, index);
                        }}
                      />
                      <label for={"imagenesDespuesNuevo-" + index}>
                        <BorderColor />
                      </label>
                    </div>
                    <Delete
                      onClick={() => {
                        eliminarImagenDespues(index);
                      }}
                    />
                  </div>
                ))}
              </div>
            </Grid>
          </Grid>
        </Grid>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
            marginTop: "25px",
          }}
        >
          <button
            type="submit"
            className="boton-verde"
            style={{ width: "80%" }}
          >
            Agregar
          </button>
          <Link
            className="boton-azul"
            style={{ width: "70%", textAlign: "center" }}
            to="/codigos-visita"
          >
            Regresar
          </Link>
        </div>
      </form>
    </div>
  );
};

export default NuevoCodigo;

import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";

import Grid from "@mui/material/Unstable_Grid2";
import TextField from "@mui/material/TextField";

import { globalContext } from "../../Context/globalContext";
import useDatabase from "../../Hooks/useDatabase";

const NuevaSubCategoria = () => {
  const global = useContext(globalContext);
  let setTitle = global.setTitle;
  setTitle("Nueva Sub Categoria");

  const [consulta, setConsulta] = useState({ consulta: "" });
  const [state, setState] = useState({
    nombre: "",
  });
  useDatabase(consulta);

  const agregarSubCategoria = (e) => {
    e.preventDefault();

    setConsulta({
      consulta: "agregarDocumento",
      collectionName: "subCategorias",
      state,
      urlRedirect: "/sub-categorias",
    });
  };

  const leerDato = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="row contenedor-1">
      <form onSubmit={agregarSubCategoria}>
        <Grid container spacing={10}>
          <Grid xs={6} className="formulario-inputs">
            <p className="titulo-1">Información de la sub categoria</p>

            <TextField
              label="Nombre de la sub categoria"
              placeholder="Nombre de la sub categoria"
              onChange={leerDato}
              id="nombre"
              name="nombre"
              variant="outlined"
              fullWidth
              required
            />
          </Grid>
          <Grid xs={6}>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              <button type="submit" className="boton-verde">
                Agregar
              </button>
              <Link className="boton-azul" to="/sub-categorias">
                Regresar
              </Link>
            </div>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default NuevaSubCategoria;

import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import CryptoJS from "react-native-crypto-js";
import { useSelector } from "react-redux";

import Grid from "@mui/material/Unstable_Grid2";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";

import { globalContext } from "../../Context/globalContext";
import useDatabase from "../../Hooks/useDatabase";

const EditarUsuario = () => {
  const global = useContext(globalContext);
  let setTitle = global.setTitle;
  setTitle("Editar Usuario");

  const { id } = useParams();

  const [consulta, setConsulta] = useState({
    consulta: "obtenerDocumento",
    collectionName: "usuarios",
    id,
  });
  const [showPassword, setShowPassword] = useState(false);
  const [state, setState] = useState(false);
  const [passwordDecrypt, setPasswordDecrypt] = useState("");

  const { document } = useSelector((state) => state.firestore);

  useDatabase(consulta);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const editarUsuario = (e) => {
    e.preventDefault();

    let ciphertext = CryptoJS.AES.encrypt(
      passwordDecrypt,
      "Y2Fhdg=="
    ).toString();
    state.password = ciphertext;

    setConsulta({
      consulta: "actualizarDocumento",
      collectionName: "usuarios",
      state,
      id,
      urlRedirect: "/usuarios",
    });

    setTimeout(() => {
      setConsulta({ consulta: "" });
    }, 10);
  };

  const leerDato = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });

    if (e.target.name === "password") setPasswordDecrypt(e.target.value);
  };

  useEffect(() => {
    if (document.password) {
      let bytes = CryptoJS.AES.decrypt(document.password, "Y2Fhdg==");
      let originalText = bytes.toString(CryptoJS.enc.Utf8);

      setPasswordDecrypt(originalText);
      setState({
        ...document,
        password: originalText,
      });
    }
  }, [document]);

  return (
    <div className="row contenedor-1">
      {state && (
        <form onSubmit={editarUsuario}>
          <Grid container spacing={10}>
            <Grid xs={6} className="formulario-inputs">
              <p className="titulo-1">Información del usuario</p>

              <TextField
                label="Nombre de usuario"
                placeholder="Nombre de usuario"
                onChange={leerDato}
                value={state.nombre}
                id="nombre"
                name="nombre"
                variant="outlined"
                fullWidth
                required
              />
              <TextField
                label="Correo"
                placeholder="admin@admin.com"
                onChange={leerDato}
                value={state.email}
                id="email"
                name="email"
                type="email"
                variant="outlined"
                fullWidth
                required
              />
              <FormControl variant="outlined" fullWidth required>
                <InputLabel htmlFor="user-password">Contraseña</InputLabel>
                <Input
                  id="user-password"
                  onChange={leerDato}
                  value={passwordDecrypt}
                  name="password"
                  type={showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>

              <FormControl style={{ marginTop: "10px" }}>
                <FormLabel id="tipo_usuario">Tipo de usuario:</FormLabel>
                <RadioGroup
                  aria-labelledby="tipo_usuario"
                  defaultValue="administrador"
                  name="tipo"
                  required
                >
                  <FormControlLabel
                    value="administrador"
                    control={<Radio />}
                    label="Administrador"
                  />
                  {/* <FormControlLabel value="male" control={<Radio />} label="Male" />
                <FormControlLabel value="other" control={<Radio />} label="Other" /> */}
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid xs={6}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <button type="submit" className="boton-verde">
                  Editar
                </button>
                <Link className="boton-azul" to="/usuarios">
                  Regresar
                </Link>
              </div>
            </Grid>
          </Grid>
        </form>
      )}
    </div>
  );
};

export default EditarUsuario;

import { useEffect, useState } from "react";
import { collection, query, onSnapshot, where } from "firebase/firestore";

import { getCollectionRealTime } from "../Redux/Features/Firestore/firestoreSlice";

import firebase from "../firebase";
import { useDispatch } from "react-redux";
const db = firebase.firestore;

const useFirebase = ({ consulta, collectionName, condicionWhere }) => {
  const dispatch = useDispatch();
  const [result, setResult] = useState(false);

  const obtenerRealTime = async () => {
    try {
      let condicional;
      if (condicionWhere)
        condicional = where(
          condicionWhere[0],
          condicionWhere[1],
          condicionWhere[2]
        );

      const q = query(collection(db, collectionName), condicional);

      onSnapshot(
        q,
        (querySnapshot) => {
          let docs = [];
          querySnapshot.forEach((doc) => {
            docs.push({ ...doc.data(), id: doc.id });

            if (docs.length === querySnapshot.size) {
              let res = dispatch(getCollectionRealTime(docs, collectionName));

              setResult({
                success: true,
                res,
              });
            }
          });

          if (querySnapshot.size === 0) {
            setResult({
              success: true,
              res: [],
            });
          }
        },
        (error) => {
          setResult({
            success: false,
          });
        }
      );

      setResult({
        success: true,
      });
    } catch (error) {
      setResult({ success: false, error });
    }
  };

  useEffect(() => {
    switch (consulta) {
      case "obtenerRealTime":
        obtenerRealTime();
        break;
      default:
        console.error("Error en opcion seleccionada");
        break;
    }
    // eslint-disable-next-line
  }, [consulta]);

  return [result];
};

export default useFirebase;

export const funtion = Object.freeze({
  usuarios: false,
  categorias: false,
  subcategorias: false,
  productos: false,
  mensajes: false,
});

export const storage = Object.freeze({
  categorias: false,
  subcategorias: false,
  productos: false,
  galeria_productos: false,
});

export const categorias = Object.freeze("categorias");
export const codigosVisitas = Object.freeze("codigosVisitas");

const config = {
  apiKey: "AIzaSyC1pvRK6utX9O0IBRjTK2gQKIkmgNXeoW0",
  authDomain: "michel-alvarez-studio.firebaseapp.com",
  projectId: "michel-alvarez-studio",
  storageBucket: "michel-alvarez-studio.appspot.com",
  messagingSenderId: "173490469615",
  appId: "1:173490469615:web:336752c8d8050fdfd8391a",
  measurementId: "G-59NQKT9E7N",
};

export default config;

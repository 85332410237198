import React, { useContext, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";

import { globalContext } from "../../Context/globalContext";
import useDatabase from "../../Hooks/useDatabase";

import Tabla from "../../Components/Tabala";

const headCells = [
  {
    id: "nombre",
    numeric: false,
    disablePadding: true,
    label: "Nombre",
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: "Correo",
  },
  {
    id: "estado",
    numeric: false,
    disablePadding: false,
    label: "Estado",
  },
  {
    id: "acciones",
    numeric: false,
    disablePadding: false,
    label: "Acciones",
  },
];

const Mensajes = () => {
  const { setTitle } = useContext(globalContext);
  setTitle("Mensajes");

  const {
    collection: { mensajes },
  } = useSelector((state) => state.firestore);

  useDatabase({
    consulta: "obtenerRealTime",
    collectionName: "mensajes",
    condicionWhere: ["archivado", "==", false],
  });

  const [notificacionMensaje, setNotificacionMensaje] = useState(false);

  // creamos una notificacion
  // TODO: perzonalizar la notificacion
  const sendNotification = () => {
    new Notification("Nuevo mensaje", {
      body: "Ha llegado un nuevo mensaje de contacto",
    });
  };

  useEffect(() => {
    if (mensajes && notificacionMensaje) {
      // TODO: agregar validacion en ssessionStorage para que no se repita esta notificacion
      if (!("Notification" in window)) {
        Swal.fire({
          title: "Error",
          text: "Tu navegador no es compatible con las notificaciones",
          confirmButtonColor: "#2DCD22",
          confirmButtonText: "Continuar",
          icon: "error",
        });
      } else if (Notification.permission === "granted") {
        if (mensajes.length > notificacionMensaje) {
          sendNotification();
          setNotificacionMensaje(mensajes.length);
        }
      } else if (Notification.permission === "denied") {
        Notification.requestPermission();
      }
    } else if (mensajes) {
      setNotificacionMensaje(mensajes.length);
    }
    // eslint-disable-next-line
  }, [mensajes]);

  return (
    <div name="collection" className="contenedor-1">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <p className="titulo-1">Todos los Mensajes</p>
      </div>

      {mensajes && (
        <div style={{ marginBottom: "80px" }}>
          <Tabla
            collectionData={{
              collection: mensajes,
              headCells,
              ver: true,
              url: "mensaje",
            }}
          />
        </div>
      )}
    </div>
  );
};

export default Mensajes;

import React, { Fragment, useContext } from "react";
import Grid from "@mui/material/Unstable_Grid2";

import { globalContext } from "../Context/globalContext";

import Loader from "./Loader/Loader";
import Header from "./Header";

const SideNav = ({ children }) => {
  const global = useContext(globalContext);
  let user = global.userGlobal;
  let title = global.title;
  let loader = global.loader;

  // para cerrar el poppop
  const cerrarImagen = () => {
    let contenedor = document.getElementById("fondo-imagen");
    contenedor.className = "fondo-imagen-off";
  };

  if (window.location.pathname !== "/")
    return (
      <Fragment>
        {/* seccion para insertar codiggo en forma de popop */}
        <div
          onClick={cerrarImagen}
          className="fondo-imagen-off"
          id="fondo-imagen"
        ></div>
        {!loader && <Loader />}

        <Header user={user} title={title} />

        <Grid
          container
          spacing={0}
          style={{ height: "calc(100vh - 80px)", marginTop: "auto" }}
        >
          <Grid xs={12} xl={9}>
            {children}
          </Grid>
        </Grid>
      </Fragment>
    );
};

export default SideNav;

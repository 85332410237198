import { useContext, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";

import { globalContext } from "../../Context/globalContext";

import { setFile, deleteFile } from "../Redux/Features/Storage/storageSlice";

const useStorage = ({
  consulta,
  file,
  files,
  deletes,
  updates,
  name,
  folder,
  urlFile,
}) => {
  const global = useContext(globalContext);
  let loader = global.loader;
  let setLoader = global.setLoader;

  const dispatch = useDispatch();
  const [result, setResult] = useState(false);

  //   subir solamente un archivo
  const subirUnArchivo = async () => {
    if (loader) setLoader(false);

    let res = await dispatch(setFile({ file, name, folder }));

    if (
      res.payload.status === "failed" ||
      res.meta.requestStatus === "failed"
    ) {
      return Swal.fire({
        title: "Error",
        text: "El campo no logro ser agregado",
        confirmButtonColor: "#2DCD22",
        confirmButtonText: "Continuar",
        icon: "error",
      });
    }

    setResult(res.payload);
  };

  //   subir multiples archivos
  // NOTA: solo funciona para actualizar codigo de visita
  const subirArchivos = async () => {
    if (loader) setLoader(false);
    let antes = [];
    let despues = [];
    let antesUpdate = [];
    let despuesUpdate = [];
    let antesDelete = [];
    let despuesDelete = [];

    console.log({ files, deletes, updates });

    // imagenes nuevas
    if (files[0].length) {
      // subida de antes imagenes nuevas
      await new Promise(async (resolve, reject) => {
        for (let index = 0; index < files[0].length; index++) {
          const file = files[0][index];

          let res = await dispatch(setFile({ file, name, folder }));

          if (
            res.payload.status === "failed" ||
            res.meta.requestStatus === "failed"
          ) {
            return Swal.fire({
              title: "Error",
              text: "El campo no logro ser agregado",
              confirmButtonColor: "#2DCD22",
              confirmButtonText: "Continuar",
              icon: "error",
            });
          }

          antes.push(res.payload);

          if (index === files[0].length - 1) resolve(true);
        }
      });
    }

    if (files[1].length) {
      // subida de despues imagenes nuevas
      await new Promise(async (resolve, reject) => {
        for (let index = 0; index < files[1].length; index++) {
          const file = files[1][index];

          let res = await dispatch(setFile({ file, name, folder }));

          if (
            res.payload.status === "failed" ||
            res.meta.requestStatus === "failed"
          ) {
            return Swal.fire({
              title: "Error",
              text: "El campo no logro ser agregado",
              confirmButtonColor: "#2DCD22",
              confirmButtonText: "Continuar",
              icon: "error",
            });
          }

          despues.push(res.payload);

          if (index === files[1].length - 1) resolve(true);
        }
      });
    }
    // fin - imagenes nuevas

    // imagenes a actualizar
    if (updates[0].length) {
      // subida, antes imagenes nuevas
      await new Promise(async (resolve, reject) => {
        for (let index = 0; index < updates[0].length; index++) {
          const file = updates[0][index].file;

          let res = await dispatch(setFile({ file, name, folder }));

          if (
            res.payload.status === "failed" ||
            res.meta.requestStatus === "failed"
          ) {
            return Swal.fire({
              title: "Error",
              text: "El campo no logro ser agregado",
              confirmButtonColor: "#2DCD22",
              confirmButtonText: "Continuar",
              icon: "error",
            });
          }

          antesUpdate.push({
            url: res.payload,
            index: updates[0][index].posicionArchivoStorage,
          });

          if (index === updates[0].length - 1) resolve(true);
        }
      });
    }

    if (updates[1].length) {
      // subida, despues imagenes nuevas
      await new Promise(async (resolve, reject) => {
        for (let index = 0; index < updates[1].length; index++) {
          const file = updates[1][index].file;

          let res = await dispatch(setFile({ file, name, folder }));

          if (
            res.payload.status === "failed" ||
            res.meta.requestStatus === "failed"
          ) {
            return Swal.fire({
              title: "Error",
              text: "El campo no logro ser agregado",
              confirmButtonColor: "#2DCD22",
              confirmButtonText: "Continuar",
              icon: "error",
            });
          }

          despuesUpdate.push({
            url: res.payload,
            index: updates[1][index].posicionArchivoStorage,
          });

          if (index === updates[1].length - 1) resolve(true);
        }
      });
    }
    // imagenes a actualizar

    // eliminar archivos
    if (deletes[0].length) {
      // eliminar archivos antes
      await new Promise(async (resolve, reject) => {
        for (let index = 0; index < deletes[0].length; index++) {
          const url = deletes[0][index].url;

          let res = await dispatch(deleteFile({ urlFile: url }));

          if (
            res.payload.status === "failed" ||
            res.meta.requestStatus === "failed"
          ) {
            return Swal.fire({
              title: "Error",
              text: "El campo no logro ser agregado",
              confirmButtonColor: "#2DCD22",
              confirmButtonText: "Continuar",
              icon: "error",
            });
          }

          antesDelete.push({ index });

          if (index === deletes[0].length - 1) resolve(true);
        }
      });
    }

    if (deletes[1].length) {
      // eliminar archivos despues
      await new Promise(async (resolve, reject) => {
        for (let index = 0; index < deletes[1].length; index++) {
          const url = deletes[1][index].url;

          let res = await dispatch(deleteFile({ urlFile: url }));

          if (
            res.payload.status === "failed" ||
            res.meta.requestStatus === "failed"
          ) {
            return Swal.fire({
              title: "Error",
              text: "El campo no logro ser agregado",
              confirmButtonColor: "#2DCD22",
              confirmButtonText: "Continuar",
              icon: "error",
            });
          }

          despuesDelete.push({ index });

          if (index === deletes[1].length - 1) resolve(true);
        }
      });
    }
    // fin - eliminar archivos

    console.log({
      antes,
      despues,
      antesUpdate,
      despuesUpdate,
      antesDelete,
      despuesDelete,
    });
    setResult({
      antes,
      despues,
      antesUpdate,
      despuesUpdate,
      antesDelete,
      despuesDelete,
    });
  };

  // eliminar un solo archivo
  const eliminarUnArchivo = async () => {
    if (loader) setLoader(false);

    let res = await dispatch(deleteFile({ urlFile }));

    if (
      res.payload.status === "failed" ||
      res.meta.requestStatus === "failed"
    ) {
      return Swal.fire({
        title: "Error",
        text: "El campo no logro ser agregado",
        confirmButtonColor: "#2DCD22",
        confirmButtonText: "Continuar",
        icon: "error",
      });
    }

    setResult(true);
  };

  useEffect(() => {
    switch (consulta) {
      case "subirUnArchivo":
        subirUnArchivo();
        break;
      case "subirArchivos":
        subirArchivos();
        break;
      case "eliminarUnArchivo":
        eliminarUnArchivo();
        break;
      default:
        setResult(false);
        break;
    }
    // eslint-disable-next-line
  }, [consulta]);

  return [result];
};

export default useStorage;

import React, { useEffect, useState, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import ReactDOM from "react-dom";

import Grid from "@mui/material/Unstable_Grid2";
import TextField from "@mui/material/TextField";

import useDatabase from "../../Hooks/useDatabase";

// iconos
import { Add, BorderColor, Delete } from "@mui/icons-material";

// configuracion de la vista
import { codigosVisitas } from "../../config.js";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";

import { globalContext } from "../../Context/globalContext";

const EditarCodigo = () => {
  const { setTitle } = useContext(globalContext);
  setTitle("Modificar codigo de visita");

  const { id } = useParams();

  const [consulta, setConsulta] = useState({
    consulta: "obtenerDocumento",
    collectionName: "codigosVisitas",
    id,
  });

  const { document } = useSelector((state) => state.firestore);

  useDatabase(consulta);

  //   imagens que ya estan subidas al storage y que se muestran en el render
  const [imagenesAntesStorage, setImagenesAntesStorage] = useState([]);
  const [imagenesDespuesStorage, setImagenesDespuesStorage] = useState([]);

  //   imagenes nuevas que se muestran en el render
  const [imagenesAntes, setImagenesAntes] = useState([]);
  const [imagenesDespues, setImagenesDespues] = useState([]);

  //   lista de imagenes que se van a subir a el storage
  const [filesAntes, setFilesAntes] = useState([]);
  const [filesDespues, setFilesDespues] = useState([]);

  //   posicion de las imagenes que se actualizaran
  const [filesAntesUpdate, setFilesAntesUpdate] = useState([]);
  const [filesDespuesUpdate, setFilesDespuesUpdate] = useState([]);

  //   lista de imagenes que se van a eliminar, solo se enlistan las que ya estaban subidas a el storage
  const [filesAntesDelete, setFilesAntesDelete] = useState([]);
  const [filesDespuesDelete, setFilesDespuesDelete] = useState([]);

  const [state, setState] = useState({});

  useEffect(() => {
    if (document.id === id) {
      setImagenesAntesStorage([...document.imagenes.antes]);
      setImagenesDespuesStorage([...document.imagenes.despues]);
      setState({ ...document });
    }
  }, [document]);

  const actualizarCodigo = async (e) => {
    e.preventDefault();

    Swal.fire({
      title: "¿Esta seguro de actualizar este campo?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#2DCD22",
      cancelButtonColor: "#d33",
      confirmButtonText: "Continuar",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.value) {
        if (
          filesDespues ||
          filesAntes ||
          filesAntesUpdate ||
          filesDespuesUpdate ||
          filesAntesDelete ||
          filesDespuesDelete
        ) {
          setConsulta({
            consulta: "actualizarDocumento",
            collectionName: codigosVisitas,
            state,
            files: [filesAntes, filesDespues],
            updates: [filesAntesUpdate, filesDespuesUpdate],
            deletes: [filesAntesDelete, filesDespuesDelete],
            id,
            urlRedirect: "/codigos-visita",
          });
        } else {
          setConsulta({
            consulta: "actualizarDocumento",
            collectionName: codigosVisitas,
            state,
            id,
            urlRedirect: "/codigos-visita",
          });
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire({
          title: "Cancelado",
          text: "El campo sigue intacto :)",
          confirmButtonColor: "#2DCD22",
          confirmButtonText: "Continuar",
          icon: "error",
        });
      }
    });
  };

  const leerDato = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  //   agregar imagenes
  const leerImagenesAntes = async (e) => {
    // copia de los archivos agregados con anterioridad y los nuevos
    let archivosAntesCopia = [...filesAntes];

    for (let index = 0; index < e.target.files.length; index++) {
      const file = e.target.files[index];
      archivosAntesCopia.push(file);
    }

    setFilesAntes(archivosAntesCopia);

    // algoritmo de precarga
    let imagenesPrecarga = [...imagenesAntes];

    await new Promise((resolve, reject) => {
      for (let index = 0; index < e.target.files.length; index++) {
        const file = e.target.files[index];

        let fileReader = new FileReader();
        fileReader.addEventListener("load", function (evt) {
          imagenesPrecarga.push(fileReader.result);

          if (index === e.target.files.length - 1) {
            resolve(true);
          }
        });

        fileReader.readAsDataURL(file);
      }
    });

    setImagenesAntes(imagenesPrecarga);
  };

  const leerImagenesDespues = async (e) => {
    // copia de los archivos agregados con anterioridad y los nuevos
    let archivosDespuesCopia = [...filesDespues];

    for (let index = 0; index < e.target.files.length; index++) {
      const file = e.target.files[index];
      archivosDespuesCopia.push(file);
    }

    setFilesDespues(archivosDespuesCopia);

    // algoritmo de precarga
    let imagenesPrecarga = [...imagenesDespues];

    await new Promise((resolve, reject) => {
      for (let index = 0; index < e.target.files.length; index++) {
        const file = e.target.files[index];

        let fileReader = new FileReader();
        fileReader.addEventListener("load", function (evt) {
          imagenesPrecarga.push(fileReader.result);

          if (index === e.target.files.length - 1) {
            resolve(true);
          }
        });

        fileReader.readAsDataURL(file);
      }
    });

    setImagenesDespues(imagenesPrecarga);
  };
  //   fin - agregar imagenes

  const verImagen = (imagenPrevia) => {
    let contenedor = window.document.getElementById("fondo-imagen");
    contenedor.className = "fondo-imagen";

    const imagen = <img src={imagenPrevia} alt="Vista previa de imagen" />;

    ReactDOM.render(imagen, contenedor);
  };

  //   imagenes del storage
  //   eliminar imagenes que estan ya subidas en el storage
  const eliminarImagenAntesStorage = (index) => {
    // verificamos si la imagen a eliminar no se habia agregado ya en la lista de imagenes a eliminar al editar la imagen
    let copiaFilesAntesUpdate = [...filesAntesUpdate];
    let busqueda = copiaFilesAntesUpdate.find(
      (dato) => dato.posicionArchivoStorage === index
    );

    if (!busqueda) {
      // si no se habia agregado a la lista la agregamos y la quitamos de lo visual
      // lista de imagenes que se van a eliminar
      let eliminarAntesCopia = [...filesAntesDelete];
      eliminarAntesCopia.push({ url: imagenesAntesStorage[index], index });
      setFilesAntesDelete(eliminarAntesCopia);
    }

    // eliminamos la imagen de lo visual
    // imagenes que se muestran
    let eliminarImagenesAntesCopia = [...imagenesAntesStorage];
    eliminarImagenesAntesCopia.splice(index, 1);
    setImagenesAntesStorage(eliminarImagenesAntesCopia);
  };

  //   eliminar imagenes que estan ya subidas en el storage
  const eliminarImagenDespuesStorage = (index) => {
    // verificamos si la imagen a eliminar no se habia agregado ya en la lista de imagenes a eliminar al editar la imagen
    let copiaFilesDespuesUpdate = [...filesDespuesUpdate];
    let busqueda = copiaFilesDespuesUpdate.find(
      (dato) => dato.posicionArchivoStorage === index
    );

    if (!busqueda) {
      // si no se habia agregado a la lista la agregamos y la quitamos de lo visual
      // lista de imagenes que se van a eliminar
      let eliminarDespuesCopia = [...filesDespuesDelete];
      eliminarDespuesCopia.push({ url: imagenesDespuesStorage[index], index });
      setFilesDespuesDelete(eliminarDespuesCopia);
    }

    // eliminamos la imagen de lo visual
    // imagenes que se muestran
    let eliminarImagenesDespuesCopia = [...imagenesDespuesStorage];
    eliminarImagenesDespuesCopia.splice(index, 1);
    setImagenesDespuesStorage(eliminarImagenesDespuesCopia);
  };

  //   editar imagenes que estan ya subidas en el storage
  const editarImagenAntesStorage = async (e, index) => {
    // logica de actualizacion de archivos
    let copiaFilesAntesDelete = [...filesAntesDelete];
    let copiaFilesAntesUpdate = [...filesAntesUpdate];

    // validamos que la imagen a actualizar no se haya cambiado en el render actual.
    let busqueda = copiaFilesAntesUpdate.findIndex(
      (archivo) => archivo.posicionArchivoStorage === index
    );

    if (busqueda >= 0) {
      // si ya se cambio agregamos la imagen en la pocicion que corresponde en los archivos a subir
      copiaFilesAntesUpdate[busqueda].file = e.target.files[0];
    } else {
      // si no se a cambiado agregamos la imagen a el final a la lista de eliminadas y agregamos la imagen a los archivos a subir
      copiaFilesAntesDelete.push({ url: imagenesAntesStorage[index] });

      let update = {
        file: e.target.files[0],
        posicionArchivoStorage: index,
        posicionArchivosEliminar: copiaFilesAntesDelete.length - 1,
      };

      copiaFilesAntesUpdate.push(update);
    }

    setFilesAntesDelete(copiaFilesAntesDelete);
    setFilesAntesUpdate(copiaFilesAntesUpdate);
    // fin de logica de actualizacion de archivos

    // logica de actualizacion de imagen visual
    let copiaImagenesAntesStorage = [...imagenesAntesStorage];

    // algoritmo de precarga
    await new Promise((resolve, reject) => {
      const file = e.target.files[0];

      let fileReader = new FileReader();
      fileReader.addEventListener("load", function (evt) {
        copiaImagenesAntesStorage[index] = fileReader.result;

        resolve(true);
      });

      fileReader.readAsDataURL(file);
    });

    setImagenesAntesStorage(copiaImagenesAntesStorage);
    // fin - logica de actualizacion de imagen visual
  };

  //   editar imagenes que estan ya subidas en el storage
  const editarImagenDespuesStorage = async (e, index) => {
    // logica de actualizacion de archivos
    let copiaFilesDespuesDelete = [...filesDespuesDelete];
    let copiaFilesDespuesUpdate = [...filesDespuesUpdate];

    // validamos que la imagen a actualizar no se haya cambiado en el render actual.
    let busqueda = copiaFilesDespuesUpdate.findIndex(
      (archivo) => archivo.posicionArchivoStorage === index
    );
    if (busqueda >= 0) {
      // si ya se cambio agregamos la imagen en la pocicion que corresponde en los archivos a subir
      copiaFilesDespuesUpdate[busqueda].file = e.target.files[0];
    } else {
      // si no se a cambiado agregamos la imagen a el final a la lista de eliminadas y agregamos la imagen a los archivos a subir
      copiaFilesDespuesDelete.push({ url: imagenesDespuesStorage[index] });

      let update = {
        file: e.target.files[0],
        posicionArchivoStorage: index,
        posicionArchivosEliminar: copiaFilesDespuesDelete.length - 1,
      };

      copiaFilesDespuesUpdate.push(update);
    }

    setFilesDespuesDelete(copiaFilesDespuesDelete);
    setFilesDespuesUpdate(copiaFilesDespuesUpdate);
    // fin de logica de actualizacion de archivos

    // logica de actualizacion de imagen visual
    let copiaImagenesDespuesStorage = [...imagenesDespuesStorage];

    // algoritmo de precarga
    await new Promise((resolve, reject) => {
      const file = e.target.files[0];

      let fileReader = new FileReader();
      fileReader.addEventListener("load", function (evt) {
        copiaImagenesDespuesStorage[index] = fileReader.result;

        resolve(true);
      });

      fileReader.readAsDataURL(file);
    });

    setImagenesDespuesStorage(copiaImagenesDespuesStorage);
    // fin - logica de actualizacion de imagen visual
  };
  //   fin - imagenes del storage

  //   *** imagenes por subir
  //   eliminar imagenes que estan por subir
  const eliminarImagenAntes = (index) => {
    // imagenes que se muestran
    let eliminarImagenesAntesCopia = [...imagenesAntes];
    eliminarImagenesAntesCopia.splice(index, 1);
    setImagenesAntes(eliminarImagenesAntesCopia);

    // archivos que se van a mandar
    let eliminarFilesAntesCopia = [...filesAntes];
    eliminarFilesAntesCopia.splice(index, 1);
    setFilesAntes(eliminarFilesAntesCopia);
  };

  //   eliminar imagenes que estan por subir
  const eliminarImagenDespues = (index) => {
    // imagenes que se muestran
    let eliminarImagenesDespuesCopia = [...imagenesDespues];
    eliminarImagenesDespuesCopia.splice(index, 1);
    setImagenesDespues(eliminarImagenesDespuesCopia);

    // archivos que se van a mandar
    let eliminarFilesDespuesCopia = [...filesDespues];
    eliminarFilesDespuesCopia.splice(index, 1);
    setFilesDespues(eliminarFilesDespuesCopia);
  };

  //   editar imagenes que estan por subir
  const editarImagenAntes = async (e, index) => {
    // imagenes que se muestran
    // algoritmo de precarga
    let copiaImagenesAntes = [...imagenesAntes];
    await new Promise((resolve, reject) => {
      const file = e.target.files[0];

      let fileReader = new FileReader();
      fileReader.addEventListener("load", function (evt) {
        copiaImagenesAntes[index] = fileReader.result;

        resolve(true);
      });

      fileReader.readAsDataURL(file);
    });

    setImagenesAntes(copiaImagenesAntes);
    // fin - logica de actualizacion de imagen visual

    // archivos que se van a mandar e.target.files[0]
    let filesAntesCopia = [...filesAntes];
    filesAntesCopia[index] = e.target.files[0];
    setFilesAntes(filesAntesCopia);
  };

  //   editar imagenes que estan por subir
  const editarImagenDespues = async (e, index) => {
    // imagenes que se muestran
    // algoritmo de precarga
    let copiaImagenesDespues = [...imagenesDespues];
    await new Promise((resolve, reject) => {
      const file = e.target.files[0];

      let fileReader = new FileReader();
      fileReader.addEventListener("load", function (evt) {
        copiaImagenesDespues[index] = fileReader.result;

        resolve(true);
      });

      fileReader.readAsDataURL(file);
    });

    setImagenesDespues(copiaImagenesDespues);
    // fin - logica de actualizacion de imagen visual

    // archivos que se van a mandar e.target.files[0]
    let filesDespuesCopia = [...filesDespues];
    filesDespuesCopia[index] = e.target.files[0];
    setFilesDespues(filesDespuesCopia);
  };
  //   fin - *** imagenes por subir

  return (
    <div className="row contenedor-1">
      <form onSubmit={actualizarCodigo}>
        {/* <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p className="titulo-1">Modificar codigo de visita</p>
        </div> */}
        {state.clienta && (
          <Grid container rowSpacing={0} columnSpacing={3}>
            <Grid md={12} lg={6} className="formulario-inputs">
              <div style={{ padding: "0px 20px" }}>
                <TextField
                  style={{ marginTop: "25px" }}
                  label="Nombre de la clienta"
                  placeholder="Nombre de la clienta"
                  defaultValue={state.clienta}
                  onChange={leerDato}
                  id="clienta"
                  name="clienta"
                  variant="outlined"
                  fullWidth
                  required
                />
                <TextField
                  style={{ marginTop: "25px" }}
                  label="Codigo de la visita"
                  placeholder="Codigo de la visita"
                  defaultValue={state.codigo}
                  onChange={leerDato}
                  id="codigo"
                  name="codigo"
                  variant="outlined"
                  fullWidth
                  required
                />
                <TextField
                  style={{ marginTop: "25px" }}
                  label="Fecha de la visita"
                  placeholder="Fecha de la visita"
                  defaultValue={state.fecha}
                  onChange={leerDato}
                  id="fecha"
                  name="fecha"
                  variant="outlined"
                  fullWidth
                  required
                />
              </div>
            </Grid>
            <Grid md={12} lg={6}>
              <div style={{ display: "flex", padding: "0px 20px" }}>
                <div>
                  <div
                    style={{
                      display: "flex",
                      gap: "15px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <p>
                      <b>Antes</b>
                    </p>
                    <div>
                      <input
                        id="imagenesAntes"
                        name="imagenesAntes"
                        className="agregar-imagen"
                        placeholder="+"
                        type="file"
                        accept="image/*"
                        onChange={leerImagenesAntes}
                        multiple
                      />
                      <label for="imagenesAntes">
                        <Add fontSize="small" />
                      </label>
                    </div>
                  </div>
                  <div className="galeria-imagenes">
                    {imagenesAntesStorage.map((imagen, index) => (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <div className="contenedor-imagen">
                          <img
                            onClick={() => {
                              verImagen(imagen);
                            }}
                            src={imagen}
                            alt={state.clienta}
                          />
                        </div>
                        <div>
                          <input
                            style={{ display: "none" }}
                            id={"imagenesAntes-" + index}
                            name="imagenesAntes"
                            type="file"
                            accept="image/*"
                            onChange={(e) => {
                              editarImagenAntesStorage(e, index);
                            }}
                          />
                          <label for={"imagenesAntes-" + index}>
                            <BorderColor />
                          </label>
                        </div>
                        <Delete
                          onClick={() => {
                            eliminarImagenAntesStorage(index);
                          }}
                        />
                      </div>
                    ))}
                    {imagenesAntes.map((imagen, index) => (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <div className="contenedor-imagen">
                          <img
                            onClick={() => {
                              verImagen(imagen);
                            }}
                            src={imagen}
                            alt={state.clienta}
                          />
                        </div>
                        <div>
                          <input
                            style={{ display: "none" }}
                            id={"imagenesAntesNuevas-" + index}
                            name="imagenesAntesNuevas"
                            type="file"
                            accept="image/*"
                            onChange={(e) => {
                              editarImagenAntes(e, index);
                            }}
                          />
                          <label for={"imagenesAntesNuevas-" + index}>
                            <BorderColor />
                          </label>
                        </div>
                        <Delete
                          onClick={() => {
                            eliminarImagenAntes(index);
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </div>

                <div>
                  <div
                    style={{
                      display: "flex",
                      gap: "15px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <p>
                      <b>Despues</b>
                    </p>
                    <div>
                      <input
                        id="imagenesDespues"
                        name="imagenesDespues"
                        className="agregar-imagen"
                        placeholder="+"
                        type="file"
                        accept="image/*"
                        onChange={leerImagenesDespues}
                        multiple
                      />
                      <label for="imagenesDespues">
                        <Add fontSize="small" />
                      </label>
                    </div>
                  </div>
                  <div className="galeria-imagenes">
                    {imagenesDespuesStorage.map((imagen, index) => (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <div className="contenedor-imagen">
                          <img
                            onClick={() => {
                              verImagen(imagen);
                            }}
                            src={imagen}
                            alt={state.clienta}
                          />
                        </div>
                        <div>
                          <input
                            style={{ display: "none" }}
                            id={"imagenesDespues-" + index}
                            name="imagenesDespues"
                            type="file"
                            accept="image/*"
                            onChange={(e) => {
                              editarImagenDespuesStorage(e, index);
                            }}
                          />
                          <label for={"imagenesDespues-" + index}>
                            <BorderColor />
                          </label>
                        </div>
                        <Delete
                          onClick={() => {
                            eliminarImagenDespuesStorage(index);
                          }}
                        />
                      </div>
                    ))}
                    {imagenesDespues.map((imagen, index) => (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <div className="contenedor-imagen">
                          <img
                            onClick={() => {
                              verImagen(imagen);
                            }}
                            src={imagen}
                            alt={state.clienta}
                          />
                        </div>
                        <div>
                          <input
                            style={{ display: "none" }}
                            id={"imagenesDespuesNuevo-" + index}
                            name="imagenesDespuesNuevo"
                            type="file"
                            accept="image/*"
                            onChange={(e) => {
                              editarImagenDespues(e, index);
                            }}
                          />
                          <label for={"imagenesDespuesNuevo-" + index}>
                            <BorderColor />
                          </label>
                        </div>
                        <Delete
                          onClick={() => {
                            eliminarImagenDespues(index);
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
            marginTop: "25px",
          }}
        >
          <button
            type="submit"
            className="boton-verde"
            style={{ width: "80%" }}
          >
            Actualizar
          </button>
          <Link
            className="boton-azul"
            style={{ width: "70%", textAlign: "center" }}
            to="/codigos-visita"
          >
            Regresar
          </Link>
        </div>
      </form>
    </div>
  );
};

export default EditarCodigo;

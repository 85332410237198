import { useEffect, useState, useContext } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  getDocument,
  getCollection,
  setDocument,
  deleteDocument,
  editDocument,
} from "../Firebase/Redux/Features/Firestore/firestoreSlice";

import useRealtime from "../Firebase/Hooks/useRealtime";
import useStorage from "../Firebase/Hooks/useStorage";

import { globalContext } from "../Context/globalContext";

const useDatabase = ({
  consulta,
  collectionName,
  condicionWhere,
  state,
  file,
  files,
  deletes,
  updates,
  id,
  urlRedirect,
}) => {
  const { collection } = useSelector((state) => state.firestore);
  const dispatch = useDispatch();
  const history = useNavigate();
  const [result, setResult] = useState(false);

  // para las consultas en realtime
  const [consultaRealTime, setConsultaRealTime] = useState({
    consulta: "",
  });
  // retorno de las consultas en realtime
  const [realtime] = useRealtime(consultaRealTime);

  // para las consultas en realtime
  const [consultaStorage, setConsultaStorage] = useState({
    consulta: "",
  });
  // retorno de las consultas en realtime
  const [storage] = useStorage(consultaStorage);
  // retorno del estado redux del storage
  const { url } = useSelector((state) => state.storage);

  const global = useContext(globalContext);
  let loader = global.loader;
  let setLoader = global.setLoader;

  // obtener documento
  const obtenerDocumento = async () => {
    if (loader) setLoader(false);

    let res = await dispatch(
      getDocument({
        collectionName,
        id,
      })
    );

    setLoader(true);

    setResult(res.payload);
  };

  // obtener colleccion
  const obtenerCollecion = async () => {
    if (!collection[collectionName]) {
      if (loader) setLoader(false);

      let res = await dispatch(
        getCollection({
          collectionName,
        })
      );

      setLoader(true);

      setResult(res.payload.data);
    }
  };

  // obtener colleccion en tiempo real
  const obtenerRealTime = async () => {
    if (!collection[collectionName]) {
      if (loader) setLoader(false);
      setConsultaRealTime({
        consulta: "obtenerRealTime",
        collectionName,
        condicionWhere,
      });
    }
  };

  // funcion de retorno de las consultas en realtime
  const obtenerArray = () => {
    setLoader(true);

    if (!realtime.success) {
      Swal.fire({
        title: "Error",
        text: "La informacion no logro ser obtenida",
        confirmButtonColor: "#2DCD22",
        confirmButtonText: "Continuar",
        icon: "error",
      });

      return setResult([]);
    }

    setResult(realtime);
  };

  // agregar un documento
  const agregarDocumento = async () => {
    if (loader) setLoader(false);

    // evaluamos si se mando un archivo y si se subio correctamente
    if (file && url !== "") state.url = storage;
    if (files && url !== "") state.imagenes = storage;

    let res = await dispatch(
      setDocument({
        collectionName,
        state,
      })
    );

    if (!loader) setLoader(true);

    if (
      res.payload.status === "failed" ||
      res.meta.requestStatus === "failed"
    ) {
      return Swal.fire({
        title: "Error",
        text: "El campo no logro ser insertado",
        confirmButtonColor: "#2DCD22",
        confirmButtonText: "Continuar",
        icon: "error",
      }).then(() => {
        history(urlRedirect);
      });
    }

    setTimeout(() => {
      Swal.fire({
        title: "Inserción exitosa",
        text: "El campo fue insertado correctamente",
        confirmButtonColor: "#2DCD22",
        confirmButtonText: "Continuar",
        icon: "success",
      }).then(() => {
        setResult(true);
        history(urlRedirect);
      });
    }, 10);
  };

  // actualizar un documento
  const actualizarDocumento = async () => {
    if (loader) setLoader(false);

    // evaluamos si se mando un archivo y si se subio correctamente
    console.log({ storage });
    if (file && url !== "") state.url = storage;

    let copiaAntes = [...state.imagenes.antes, ...storage.antes];
    let copiaDespues = [...state.imagenes.despues, ...storage.despues];

    if (files && url !== "") {
      // cambiamos las urls de los archivos actualizados del antes
      storage.antesUpdate.map((archivo) => {
        copiaAntes[archivo.index] = archivo.url;
      });

      // cambiamos las urls de los archivos actualizados del despues
      storage.despuesUpdate.map((archivo) => {
        copiaDespues[archivo.index] = archivo.url;
      });

      let imagenes = { antes: copiaAntes, despues: copiaDespues };

      state.imagenes = imagenes;
    }

    if (storage.antesDelete.length > 0 || storage.despuesDelete.length > 0) {
      // eliminamos las urls de los archivos actualizados del antes
      storage.antesDelete.map((archivo) => {
        console.log("antes", { archivo });
        // si no existe el index fue una imagen que se elimino por actualizacion de imagen
        if (archivo.index) {
          copiaAntes.splice(archivo.index, 1);
        }
      });

      // eliminamos las urls de los archivos actualizados del despues
      storage.despuesDelete.map((archivo) => {
        console.log("despues", { archivo });
        // si no existe el index fue una imagen que se elimino por actualizacion de imagen
        if (archivo.index) {
          copiaDespues.splice(archivo.index, 1);
        }
      });

      let imagenes = { antes: copiaAntes, despues: copiaDespues };

      console.log({ imagenes });
      state.imagenes = imagenes;
    }

    let res = await dispatch(
      editDocument({
        collectionName,
        id,
        state,
      })
    );

    if (!loader) setLoader(true);

    if (
      res.payload.status === "failed" ||
      res.meta.requestStatus === "failed"
    ) {
      return Swal.fire({
        title: "Error",
        text: "El campo no se pudo actualizar",
        confirmButtonColor: "#2DCD22",
        confirmButtonText: "Continuar",
        icon: "error",
      });
    }

    setTimeout(() => {
      Swal.fire({
        title: "Modificación exitosa",
        text: "El campo fue modificado correctamente",
        confirmButtonColor: "#2DCD22",
        confirmButtonText: "Continuar",
        icon: "success",
      }).then(() => {
        setLoader(true);
        setResult(true);
        history(urlRedirect);
      });
    }, 10);
  };

  // logica para la eliminacion
  const logicaEliminar = async () => {
    // evaluamos que el documento a eliminar tenga un archivo
    if (state.url && !storage) {
      // cuaneta con archivo y lo vamos a eliminar
      if (await preguntaEliminar()) eliminarArchivo();
    } else if (!state.url) {
      // no cuenta con archivo
      if (await preguntaEliminar()) eliminarDocumento();
    } else {
      // cuenta con archivo y ya se elimino
      eliminarDocumento();
    }
  };

  // preguntamos si desea eliminar el documento
  // NOTA: se usa ya que hay dos opciones de eliminar el documento, si cuanta o no con un archivo, continua...
  // NOTA: continuacion...si no tiene archivo pregunta si lo quiere eliminar y despues lo elimina el documento, continua...
  // NOTA: continuacion...si  tiene archivo pregunta si lo quiere eliminar y despues lo elimina el archivo y despues el docuemnto, continua...
  const preguntaEliminar = async () => {
    setResult(false);
    let res = await new Promise((resolve, reject) => {
      Swal.fire({
        title: "¿Esta seguro de eliminar este campo?",
        text: "No se podra recuperar!",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#2DCD22",
        cancelButtonColor: "#d33",
        confirmButtonText: "Continuar",
        cancelButtonText: "Cancelar",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.value) {
          resolve(true);
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          reject(false);
          Swal.fire({
            title: "Cancelado",
            text: "El campo sigue intacto :)",
            confirmButtonColor: "#2DCD22",
            confirmButtonText: "Continuar",
            icon: "error",
          }).then(() => {
            setResult("false");
          });
        }
      });
    });

    return res;
  };

  // eliminar un documento
  const eliminarDocumento = async () => {
    if (loader) setLoader(false);

    let res = await dispatch(
      deleteDocument({
        collectionName,
        id: state.id,
      })
    );

    if (!loader) setLoader(true);

    if (
      res.payload.status === "failed" ||
      res.meta.requestStatus === "failed"
    ) {
      return Swal.fire({
        title: "Error",
        text: "El campo no logro ser eliminado",
        confirmButtonColor: "#2DCD22",
        confirmButtonText: "Continuar",
        icon: "error",
      });
    }

    setTimeout(() => {
      Swal.fire({
        title: "Eliminación exitosa",
        text: "El campo fue eliminado correctamente",
        confirmButtonColor: "#2DCD22",
        confirmButtonText: "Continuar",
        icon: "success",
      }).then(() => {
        setResult(true);
        history(urlRedirect);
      });
    }, 10);
  };

  // eliminar archivo
  const eliminarArchivo = async () => {
    setConsultaStorage({
      consulta: "eliminarUnArchivo",
      urlFile: state.url,
    });
  };

  useEffect(() => {
    if (!realtime) {
      switch (consulta) {
        case "obtenerDocumento":
          obtenerDocumento();
          break;
        case "obtenerCollecion":
          obtenerCollecion();
          break;
        case "obtenerRealTime":
          obtenerRealTime();
          break;
        case "agregarDocumento":
          // evaluamos si esta amndando un archivo y si este archivo aun no se a subido
          if (file && !storage) {
            // opcion para subir un solo archivo
            // priemro: subimos el archivo
            setConsultaStorage({
              consulta: "subirUnArchivo",
              name: state.nombre,
              folder: collectionName,
              file,
            });
          } else if (files && !storage) {
            // opcion para subir multiples archivos
            // primero: subimos los archivos
            setConsultaStorage({
              consulta: "subirArchivos",
              name: state.codigo,
              folder: `imagenes/${collectionName}/${state.codigo}`,
              files,
              deletes,
              updates,
            });
          } else {
            // segundo: agregamos el docuemnto
            agregarDocumento();
          }
          break;
        case "actualizarDocumento":
          // evaluamos si esta amndando un archivo y si este archivo aun no se a subido
          if (file && !storage) {
            setConsultaStorage({
              consulta: "subirUnArchivo",
              name: state.nombre,
              folder: collectionName,
              file,
            });
          } else if (files && !storage) {
            // opcion para subir multiples archivos
            // primero: subimos los archivos
            // NOTA: esto solo funciona para los codigos de visita
            setConsultaStorage({
              consulta: "subirArchivos",
              name: state.codigo,
              folder: `imagenes/${collectionName}/${state.codigo}`,
              files,
              updates,
              deletes,
            });
          } else {
            // segundo: agregamos el docuemnto
            actualizarDocumento();
          }
          break;
        case "eliminarDocumento":
          logicaEliminar();
          break;
        case "reset":
          setConsultaStorage({
            consulta: "",
          });
          break;
        default:
          break;
      }
    } else {
      // consulta en realtime
      obtenerArray();
    }
    // eslint-disable-next-line
  }, [consulta, realtime, storage]);

  return [result];
};

export default useDatabase;

import React, { Fragment, useContext, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { globalContext } from "../../Context/globalContext";
import useDatabase from "../../Hooks/useDatabase";

const Mensaje = () => {
  const { setTitle } = useContext(globalContext);
  setTitle("Información del mensaje");

  const { id } = useParams();

  const [consulta, setConsulta] = useState({
    consulta: "obtenerDocumento",
    collectionName: "mensajes",
    id,
  });

  const { document } = useSelector((state) => state.firestore);

  useDatabase(consulta);

  const fechaString = (fecha) => {
    let meses = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];

    let fechaDate = new Date(fecha * 1000);
    let mes = fechaDate.getMonth();
    let dia = fechaDate.getDate();
    let anio = fechaDate.getFullYear();

    return dia + " de " + meses[mes] + " del " + anio;
  };

  const atender = (e) => {
    e.preventDefault();

    let state = { ...document };
    state.atendido = true;

    setConsulta({
      consulta: "actualizarDocumento",
      collectionName: "mensajes",
      state,
      id,
      urlRedirect: "/mensajes",
    });

    setTimeout(() => {
      setConsulta({ consulta: "" });
    }, 10);
  };

  const archivar = (e) => {
    e.preventDefault();

    let state = { ...document };
    state.archivado = true;

    setConsulta({
      consulta: "actualizarDocumento",
      collectionName: "mensajes",
      state,
      id,
      urlRedirect: "/mensajes",
    });

    setTimeout(() => {
      setConsulta({ consulta: "" });
    }, 10);
  };

  return (
    <div name="collection" className="contenedor-1">
      {document.nombre && (
        <Fragment>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <p>
              <b>{fechaString(document.fecha.seconds)}</b>
            </p>
            {document.atendido ? (
              <div className="atendido">
                <p>Atendido</p>
              </div>
            ) : (
              <div className="pendiente">
                <p>Pendiente</p>
              </div>
            )}
          </div>
          <p style={{ fontSize: "30px", margin: "0", lineHeight: "30px" }}>
            {document.nombre.toUpperCase()}
          </p>
          <p>
            <b>Correo:</b> {document.email}
          </p>
          <p>
            <b>Mensaje:</b>
            <br />
            {document.mensaje}
          </p>

          <div style={{ marginTop: "30px", display: "flex", gap: "15px" }}>
            {!document.atendido ? (
              <button onClick={atender} className="boton-verde">
                Atender
              </button>
            ) : (
              <button onClick={archivar} className="boton-morado">
                Archivar
              </button>
            )}
            <Link className="boton-azul" to="/mensajes">
              Regresar
            </Link>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default Mensaje;

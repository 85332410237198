import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import Grid from "@mui/material/Unstable_Grid2";
import TextField from "@mui/material/TextField";

import { globalContext } from "../../Context/globalContext";
import useDatabase from "../../Hooks/useDatabase";

const EditarCategoria = () => {
  const global = useContext(globalContext);
  let setTitle = global.setTitle;
  setTitle("Editar Categoria");

  const { id } = useParams();

  const [consulta, setConsulta] = useState({
    consulta: "obtenerDocumento",
    collectionName: "categorias",
    id,
  });
  const [state, setState] = useState(false);

  const { document } = useSelector((state) => state.firestore);

  useDatabase(consulta);

  const editarUsuario = (e) => {
    e.preventDefault();

    setConsulta({
      consulta: "actualizarDocumento",
      collectionName: "categorias",
      state,
      id,
      urlRedirect: "/categorias",
    });

    setTimeout(() => {
      setConsulta({ consulta: "" });
    }, 10);
  };

  const leerDato = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (document.nombre) {
      setState({
        ...document,
      });
    }
  }, [document]);

  return (
    <div className="row contenedor-1">
      {state && (
        <form onSubmit={editarUsuario}>
          <Grid container spacing={10}>
            <Grid xs={6} className="formulario-inputs">
              <p className="titulo-1">Información de la categoria</p>

              <TextField
                label="Nombre de la categoria"
                placeholder="Nombre de la categoria"
                onChange={leerDato}
                value={state.nombre}
                id="nombre"
                name="nombre"
                variant="outlined"
                fullWidth
                required
              />
            </Grid>
            <Grid xs={6}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <button type="submit" className="boton-verde">
                  Editar
                </button>
                <Link className="boton-azul" to="/categorias">
                  Regresar
                </Link>
              </div>
            </Grid>
          </Grid>
        </form>
      )}
    </div>
  );
};

export default EditarCategoria;

import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import TextField from "@mui/material/TextField";

import { globalContext } from "../../Context/globalContext";
import useDatabase from "../../Hooks/useDatabase";

import Tabla from "../../Components/Tabala";

const headCells = [
  {
    id: "clienta",
    numeric: false,
    disablePadding: true,
    label: "Clienta",
  },
  {
    id: "codigo",
    numeric: false,
    disablePadding: true,
    label: "Codigo",
  },
  {
    id: "acciones",
    numeric: false,
    disablePadding: false,
    label: "Acciones",
  },
];

const Codigos = () => {
  const { setTitle } = useContext(globalContext);
  setTitle("Codigos de Visita");

  const [codigos, setCodigos] = useState();

  const {
    collection: { codigosVisitas },
  } = useSelector((state) => state.firestore);

  useEffect(() => {
    if (codigosVisitas) {
      setCodigos(codigosVisitas);
    }
  }, [codigosVisitas]);

  useDatabase({
    consulta: "obtenerRealTime",
    collectionName: "codigosVisitas",
  });

  const leerDato = (e) => {
    let copiaCodigosVisitas = [...codigosVisitas];

    let filtro = copiaCodigosVisitas.filter(
      (codigo) =>
        codigo.clienta.trim().toLowerCase().includes(e.target.value) ||
        codigo.codigo.trim().toLowerCase().includes(e.target.value)
    );

    setCodigos(filtro);
  };

  return (
    <div name="collection" className="contenedor-1">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <p className="titulo-1">Todos los codigos de visita</p>
        <Link className="boton-morado" to="/nuevo-codigos-visita">
          Nuevo Codigo
        </Link>
      </div>

      <div>
        <TextField
          style={{ marginTop: "25px" }}
          label="Nombre o Codigo"
          placeholder="Nombre o Codigo"
          onChange={leerDato}
          id="codigo"
          name="codigo"
          variant="outlined"
          fullWidth
        />
      </div>

      {codigos && (
        <div style={{ marginBottom: "80px" }}>
          <Tabla
            collectionData={{
              collection: codigos,
              collectionName: "codigosVisitas",
              headCells,
              eliminar: true,
              editar: true,
              url: "codigos-visita",
            }}
          />
        </div>
      )}
    </div>
  );
};

export default Codigos;

import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import SideNav from "../Components/SideNav";

// *** administrador ***  //
import Login from "../Views/Login";

// usuarios
import Usuarios from "../Views/Usuarios/Usuarios";
import NuevoUsuario from "../Views/Usuarios/NuevoUsuario";
import EditarUsuario from "../Views/Usuarios/EditarUsuario";

// mensajes
import Mensajes from "../Views/Mensaje/Mensajes";
import Mensaje from "../Views/Mensaje/Mensaje";

// categorias
import Categorias from "../Views/Categorias/Categorias";
import NuevaCategoria from "../Views/Categorias/NuevaCategoria";
import EditarCategoria from "../Views/Categorias/EditarCategoria";

// sub categorias
import SubCategorias from "../Views/SubCategorias/SubCategorias";
import NuevaSubCategoria from "../Views/SubCategorias/NuevaSubCategoria";
import EditarSubCategoria from "../Views/SubCategorias/EditarSubCategoria";

// productos
import Productos from "../Views/Productos/Productos";
import NuevoProducto from "../Views/Productos/NuevoProducto";

// codigos de visita
import Codigos from "../Views/CodigoVisita/Codigos";
import NuevoCodigo from "../Views/CodigoVisita/NuevoCodigo";
import EditarCodigo from "../Views/CodigoVisita/EditarCodigo";

const Routers = () => (
  <BrowserRouter>
    <Routes>
      <Route exact path="/" element={<Login />} />
    </Routes>

    <SideNav>
      <Routes>
        {/* usuarios */}
        <Route path="/usuarios" element={<Usuarios />} />
        <Route path="/nuevo-usuario" element={<NuevoUsuario />} />
        <Route path="/editar-usuario/:id" element={<EditarUsuario />} />

        {/* mensajes */}
        <Route path="/mensajes" element={<Mensajes />} />
        <Route path="/ver-mensaje/:id" element={<Mensaje />} />

        {/* Categorias */}
        <Route path="/categorias" element={<Categorias />} />
        <Route path="/nueva-categoria" element={<NuevaCategoria />} />
        <Route path="/editar-categoria/:id" element={<EditarCategoria />} />

        {/* sub categorias */}
        <Route path="/sub-categorias" element={<SubCategorias />} />
        <Route path="/nueva-sub-categoria" element={<NuevaSubCategoria />} />
        <Route
          path="/editar-sub-categoria/:id"
          element={<EditarSubCategoria />}
        />

        {/* productos */}
        <Route path="/productos" element={<Productos />} />
        <Route path="/nuevo-producto" element={<NuevoProducto />} />

        {/* Codigos de visita */}
        <Route path="/codigos-visita" element={<Codigos />} />
        <Route path="/nuevo-codigos-visita" element={<NuevoCodigo />} />
        <Route path="/editar-codigos-visita/:id" element={<EditarCodigo />} />
      </Routes>
    </SideNav>
  </BrowserRouter>
);

export default Routers;

import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import { globalContext } from "../../Context/globalContext";
import useDatabase from "../../Hooks/useDatabase";

import Tabla from "../../Components/Tabala";

const headCells = [
  {
    id: "nombre",
    numeric: false,
    disablePadding: true,
    label: "Nombre",
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: "Correo",
  },
  {
    id: "acciones",
    numeric: false,
    disablePadding: false,
    label: "Acciones",
  },
];

const Usuarios = () => {
  const { setTitle } = useContext(globalContext);
  setTitle("Usuarios");

  const {
    collection: { usuarios },
  } = useSelector((state) => state.firestore);

  useDatabase({ consulta: "obtenerRealTime", collectionName: "usuarios" });

  return (
    <div name="collection" className="contenedor-1">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <p className="titulo-1">Todos los usuarios</p>
        <Link className="boton-morado" to="/nuevo-usuario">
          Nuevo Usuario
        </Link>
      </div>

      {usuarios && (
        <div style={{ marginBottom: "80px" }}>
          <Tabla
            collectionData={{
              collection: usuarios,
              collectionName: "usuarios",
              headCells,
              eliminar: true,
              editar: true,
              url: "usuario",
            }}
          />
        </div>
      )}
    </div>
  );
};

export default Usuarios;

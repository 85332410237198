import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";

import {
  collection,
  query,
  where,
  getDocs,
  addDoc,
  deleteDoc,
  updateDoc,
  doc,
} from "firebase/firestore";

import firebase from "../../../firebase";
const db = firebase.firestore;

const firestoreAdapter = createEntityAdapter();

const initialState = firestoreAdapter.getInitialState({
  collection: {},
  document: {},
  status: "idle",
  error: null,
});

// Serializador de objetos
const serializarObjeto = (array) => {
  let array_serializado = [];

  // ciclamos el objeto mandado
  array.map((objeto) => {
    // copiamos el valor acyual del ciclo
    let copiaObjeto = { ...objeto };

    // obteniendo los tipos de datos en la respuesta
    for (let clave in copiaObjeto) {
      // Realiza alguna operación sobre el valor asociado a la clave
      if (typeof copiaObjeto[clave] === "object") {
        // serializamos
        let string = JSON.stringify(copiaObjeto[clave]);
        let serializado = JSON.parse(string);

        // reasignamos el valor
        copiaObjeto[clave] = serializado;
      }
    }
    // integramos al nuevo array serializado
    return array_serializado.push(copiaObjeto);
  });

  return array_serializado;
};

// Thunk functions for get a document to a collection
export const getDocument = createAsyncThunk(
  "firestore/getDocument",
  async ({ collectionName, id = null }) => {
    try {
      const snapShot = await db.collection(collectionName).doc(id).get();

      return { ...snapShot.data(), id: snapShot.id };
    } catch (error) {
      return { error, status: "failed" };
    }
  }
);

// Thunk functions for get all documents to a collection
export const getCollection = createAsyncThunk(
  "firestore/getCollection",
  async ({ collectionName, whereCondicion = null }) => {
    // data to return from database
    var data = [];

    try {
      // evaluate params for queries to database
      var whereFuncion = null;

      if (whereCondicion !== null) {
        var iterator = whereCondicion.values();
        whereFuncion = where(
          iterator.next().value,
          iterator.next().value,
          iterator.next().value
        );
      }

      const q = query(collection(db, collectionName), whereFuncion);

      const querySnapshot = await getDocs(q);
      await querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        data.push({ ...doc.data(), id: doc.id });
      });

      return data;
    } catch (error) {
      return { error, status: "failed" };
    }
  }
);

// Thunk functions for add a document to a collection
export const setDocument = createAsyncThunk(
  "firestore/setDocument",
  async ({ collectionName, state }) => {
    try {
      const docRef = await addDoc(collection(db, collectionName), state);

      return docRef;
    } catch (error) {
      return { error, status: "failed" };
    }
  }
);

// Thunk functions for delete a document to a collection
export const deleteDocument = createAsyncThunk(
  "firestore/deleteDocument",
  async ({ collectionName, id }) => {
    try {
      await deleteDoc(doc(db, collectionName, id));

      return true;
    } catch (error) {
      return { error, status: "failed" };
    }
  }
);

// Thunk functions for edit a document
export const editDocument = createAsyncThunk(
  "firestore/editDocument",
  async ({ collectionName, id, state }) => {
    try {
      await updateDoc(doc(db, collectionName, id), state);

      return true;
    } catch (error) {
      return { error, status: "failed" };
    }
  }
);

const firestoreSlice = createSlice({
  name: "firestore",
  initialState,
  reducers: {
    // reducer for get documnets of a collection in real time
    getCollectionRealTime: {
      reducer(state, action) {
        state.collection[action.payload.collection] = action.payload.docs;
      },
      prepare(docs, collection) {
        let serializado = serializarObjeto(docs);

        return {
          payload: { docs: serializado, collection },
        };
      },
    },
  },
  extraReducers: (builder) => {
    builder
      // control de estado de la promesa para getDocument
      .addCase(getDocument.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getDocument.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.document = action.payload;
      })
      .addCase(getDocument.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      // control de estado de la promesa para getCollection
      .addCase(getCollection.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getCollection.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.collection[action.meta.arg.collectionName] = action.payload;
      })
      .addCase(getCollection.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      // control de estado de la promesa para setDocument
      .addCase(setDocument.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(setDocument.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.collection = action.payload;
      })
      .addCase(setDocument.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      // control de estado de la promesa para deleteDocument
      .addCase(deleteDocument.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(deleteDocument.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(deleteDocument.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      // control de estado de la promesa para editDocument
      .addCase(editDocument.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(editDocument.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(editDocument.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { getCollectionRealTime } = firestoreSlice.actions;

export default firestoreSlice.reducer;
